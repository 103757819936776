.toggle-switch-container {
  display:relative;
  left:2vw;
  padding-top:12px;
  bottom:0;
  margin-bottom:2vmin;
  margin-left:0vmin;
  margin-right:auto;
  align-items: center;
  margin-right:0.5vmax;
  z-index:300;
}

.toggle-switch {
  width:48px;
  height:24px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.toggle-switch input {
  display: none;
}

.slider {
  align-self: center;
  position: relative;
  display:flex;
  cursor: pointer;
  align-items: center;
  font-size:1rem;
  border-radius:20px;
  width:48px;
  height:24px;
  background-color:#5FCFF9;;
  transition: 1.4s;
}


.slider:before {
  position: relative;
  border-radius:20px;
  content: "";
  margin-left:4px;
  width:24px;
  height:16px;
  background-color: white;
  transition: 1.4s;
}

input:checked + .slider {
  background-color:#5FFABD;
}

input:checked + .slider:before {
  transform:translateX(16px);
}





@media screen and (max-width: 599px) {

  .toggle-switch-container {
    width:36px;
    height:18px;
    display:flex;
    position:absolute;
    left:auto;
    right:0;
    padding-top:0;
    top: 20vh;
    margin-bottom:0;
    margin-left:0vmin;
    margin-right:auto;
    align-items: center;
    margin-right:0vmax;
    z-index:300;
  }

  .toggle-switch {
    width:36px;
    height:18px;
    position: relative;
    display: flex;
    flex-direction: row;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .slider {
    align-self: center;
    position: relative;
    display:flex;
    cursor: pointer;
    align-items: center;
    font-size:1rem;
    border-radius:20px;
    width:36px;
    height:18px;
    background-color:#5FCFF9;;
    transition: 1.4s;
  }
  
  
  .slider:before {
    position: relative;
    border-radius:20px;
    content: "";
    margin-left:3px;
    width:18px;
    height:12px;
    background-color: white;
    transition: 1.4s;
  }
  
  input:checked + .slider {
    background-color: #5FFABD;
  }
  
  input:checked + .slider:before {
    transform:translateX(12px);
  }
  
}  