:root, :root.dark {
  --fc-today-bg-color: inherit !important;
  /* background-image: url('./images/character_background.png'); */
  background: #181818;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background: #181818;
  --white: #fdfdfd;
  --gray: #dddddd;
  --black: #242428;
  --shadow: #272727;
  --highlight: rgb(95, 250, 189);
  --title:rgb(253, 213, 91);
  --text: #fdfdfd;
  --textcon: #242424;
}

.home {
  background-position-x: 9vw;
  background-position-y: 3vh;
  background-repeat: no-repeat;
  background-size: 10em;
  height:100vh;
    width:100vw;
}



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.header_container {
  padding-top: 1vh;
  margin-right: 2vw;
  margin-left: 2vw;
  width: 96vw;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 6vh;
  flex-wrap: wrap;
  justify-content: space-between;

}


.sidemenu {}

.nav-bar {
  background-color: var(--background); 
  color: #fff;
  padding-left:4vw;
  padding-right:4vw;
  position: fixed;
  top: 0; 
  left: 0; 
  width: 92vw; 
  height: min-content;
  z-index: 1001; 
  box-shadow: 0 1px 0px var(--highlight); 
}

.babylogo{
  color:var(--title);
  font-size:1rem;
  margin-left:auto;
}

.game_title_container_nav{
    display: flex;
    color:var(--highlight);
    flex-wrap: wrap;
    flex-direction: row;
    font-size:1rem;
    margin-bottom:0vmax;
    padding-bottom:1vh;
    padding-top:1vh;
    margin-top:0;
    height: 6vh;
    font-weight: 600;
    min-width: 92vw;
    justify-content:flex-start;
    align-items: center;
    align-content: flex-end;
    position: relative;
  }




.selected_lan2 {
  color:var(--highlight);
  font-size:32px;
  height:32px;
  position:relative;
  z-index:999;
  line-height: normal;
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 0em;

}

.selected_lan2:hover {
  position:relative;
  font-size:2rem;
  border-bottom:1px solid var(--highlight);
  z-index:999;
}



.language-dropdown2 {
  position:relative;
 z-index:999;
}

/* dropdown 드랍다운 위치 조정 */



/* .sidebar {
  vertical-align: middle;
  width: 24px;
  height: 32px;
  flex-shrink: 0;
  background: #ADF1EE;
  display: none;

} */

.title_container {
  width: 100%;
  display: flex;
  position: relative;
  height: 8vh;
  margin-bottom:2vh;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-end;
}


.title {
  color: var(--title);
  margin-left: 17vw;
  font-size: 5rem;
  font-style: normal;
  font-weight: 900;
  margin-block-start: 0em;
  margin-top:auto;
  margin-block-end: 0em;

}


.subtitle{
  padding-left:1vmax;
  margin-right:auto;
  font-weight:600;
  align-self:end;
  font-size:1rem;
  color:var(--title);
  opacity:0.8;

}

.calendarname{
  margin-right:auto;
  font-weight:600;
  align-self:end;
  font-size:1rem;
  color:var(--highlight);
  opacity:0.8;
  margin-block-start: 0em;
  margin-block-end: 0em;

}


.main_content_container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.menu{
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  height:80vh;
  flex-direction: column;
  padding-top: 1vh;
  padding-bottom:2vh;
  min-width: 150px;
  width: 13vw;
  max-width: 13vw;
  margin-left: 2vw;
  margin-right: 1vw;
  align-content:flex-start;
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
}


.game_title_container {
  color:var(--highlight);
  font-weight: 600;
  display: flex;
  flex-wrap: wrap;
  height:auto;
  flex-direction: row;
  padding-bottom:2vh;
  min-width: 150px;
  width: 13vw;
  max-width: 13vw;
  align-content:flex-start;
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
}

.game_title {
  width: 8vmin;
  height:8vmin;
  border-radius: 20px;
  overflow:hidden;
  margin-right:1vw;
  display: flex;

}

.game_title:hover {
  border:1px solid var(--highlight);

}

.active-tab {
  border:1px solid var(--highlight);
}

.icon{
  border-radius: 20px;
    width: 100%;
    object-fit: contain;
}



.content-text {
  position: absolute;
  bottom: 0; 
  right:0;
  margin-right:0vmin;
  margin-bottom:4vmin;
  color: #FDFDFD;
  font-size: 1rem;
  font-weight: 500;
  z-index:99;
  color:var(--highlight);
  border-radius: 20px;
  /* background-color: var(--highlight); */
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}


.tab-content {
  flex-grow: 1;
  min-height:83vh;
  margin-right: 1vw;
  margin-left: 1vw;
  position: relative;
}
/* 
.cal-container {
  position:absolute;
  overflow:auto;
  width:min-content;
} */

.fc {
  overflow: scroll !important;
  position: relative;
  height: 80vh;
  width: 100%;
  /* margin-right: 1vw;
  margin-left: 1vw; */
  margin-top: 2.5vh;
  border-radius: 20px;
  background-color: var(--background);
  box-shadow: 0px 0px 3px 0.2px var(--shadow);
}

.tab-content:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: var(--background);
}

.tab-content:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-color: var(--background);
  z-index: 99;
}


.todo-title {
  pointer-events: auto;
  color: var(--text);
  right: 0;
  margin-top: 2.5vh;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40vh;
  position: relative;
  min-width: 280px;
  height: 80vh;
  margin-left: 1vw;
  margin-right: 2vw;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
}

.tdc1 {
  padding: 1vh 0.5vh;
  width: 40vh;
  min-width: 280px;
  border-radius: 20px;
  background-color: var(--shadow);
  position: relative;
  height: 39vh;
  display: flex;
  flex: 2 2 0%;
  flex-direction: column;
  font-size: 1rem;
  word-break: normal;
  font-style: normal;
  line-height:1.5rem;
  box-shadow: 0px 0px 3px 0.2px var(--shadow);
  max-height: 50%;
}

.textbox{
  padding:0.5vh 1vh;
}

.pollcontainer {
  padding: 0.5vh 0.5vh;
  background-color: var(--shadow);
  border-radius: 20px;
  width: 40vh;
  height:40vh;
  min-width: 280px;
  box-shadow: 0px 0px 3px 0.2px var(--shadow);
  flex: 2 0 10%;
  justify-content: center; /* 가로 중앙 정렬 */
  display: flex;
  overflow: hidden;
  margin-bottom: 1vh;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--highlight);
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  justify-content: center;
  align-items: center;
}


.poll {
  background-color: var(--shadow);
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  width: 40vh;
  flex-grow:1;
  
  min-width: 280px;
}


.pollsection {
  border-radius: 20px;
  margin-bottom: 0.5vh;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-size: 0.5rem;
}


.pollup {
  margin-block-start: 0em;
  margin-block-end: 0em;
  color:var(--black);
   /* flex-grow:4; */
  display: flex;
  overflow:hidden;
  font-size:0.8rem;
  font-weight:400;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  align-items:flex-end;
  text-shadow:0px 0px 1px var(--black);
}

.polldown {
  background-color: #181818aa;
  overflow:hidden;
  height: 100%;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size:0.8rem;
  color:var(--black);
  text-shadow:0px 0px 1px var(--black);
  font-weight:400;
  /* flex-grow:4; */
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: flex-start;
  align-items:flex-end;

}

.upvote{
  width:100%;
background-color: #5FFABDaa;
text-align:right;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
}


.upvote:hover{
 background-color:#5FFABD;
  }

.upvote_voted{
  background-color:#5FFABD;
}


.upvote_unvoted{
  background-color:#5FFABD11;
}



.downvote{
  width:100%;
  background-color:#FA78B1aa;
  border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
  }

.downvote:hover{
  background-color:#fa78b1;
}

.downvote_voted{
  background-color:#fa78b1;
}


.downvote_unvoted{
  background-color:#fa78b111;
}

.pollbutton1 {
  width:100%;
  align-self:left;
  background-color:transparent;
  border:none;
  flex:1;
  right: 0;
  position:relative;
  /* padding:1vh 1vh; */
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size:1.5rem;
  color:var(--gray);
  transform-origin: center center;
  transform: scale(1); /* 초기 크기 */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.pollbutton1:hover {
  background-color: #fdfdfd20;
  -webkit-filter:none;
  color:var(--highlight);
  font-size: 1.5rem;
  transform: rotate(10deg) scale(2);; /* 커지는 크기 */
}

.polldone1 {
  font-size:1.5rem;
  right: 0;
  height: 50%;
  background-color:transparent;
}


.pollbutton2 {
  width:100%;
  align-self:left;
  background-color:transparent;
  border:none;
  flex:1;
  right: 0;
  position:relative;
  /* padding:1vh 1vh; */
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size:1.5rem;
  color:var(--gray);
  transform-origin: center center;
  transform: scale(1); /* 초기 크기 */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.pollbutton2:hover {
  background-color: #fdfdfd20;
  -webkit-filter:none;
  color:#fa78b1;
  font-size: 1.5rem;
  transform: rotate(10deg) scale(2); /* 커지는 크기 */
}

.polldone2 {
  font-size:1.5rem;
  right: 0;
  height: 50%;
  background-color:transparent;
}


.polldone {
  transition: opacity 1s ease;
  opacity:0;
  background-color:transparent;
}






.tdc2 {
  margin-top: 1vh;
  padding: 0.5vh 0.5vh;
  width: 40vh;
  min-width: 280px;
  min-height: 240px;
  height:40vh;
  border-radius: 20px;
  background-color: var(--shadow);
  font-size: 1rem;
  flex:3 3 25%;
  font-style: normal;
  line-height: normal;
  box-shadow: 0px 0px 3px 0.2px var(--shadow);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50%;
}


.tdc2container {
  position: relative;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  overflow: scroll;
}


.bili {
  border-radius: 20px;
  width: auto;
  height: 25vw;
}

.tiktok-embed {
  margin: 0 !important;
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
}





.tab-background {
}


.tab-item {
  width: calc(90%);
  min-width: 300px;
  display: none;
}

.active-tab-item {
  display: block;
}

/* 기존 스타일 유지 */

.fc-daygrid-event-harness {}

.fc table {
  table-layout: auto;
}

.fc-view>table {
  min-width: 0;
  width: auto;
}

.fc-axis {
  min-width: 30px;
  /*the width of times column*/
  width: 30px;
  /*the width of times column*/
}

.fc-day,
.fc-resource-cell,
.fc-content-col {
  min-width: 30px;
  width: 30px;
}





.fc-daygrid-event-harness .fc-event-main i {
  position: sticky !important;
  /* 스크롤 영역 내에서의 위치 */
  left: 6px;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
  color: var(--black);
  font-style: normal;
  align-self: flex-end;
  line-height: normal;
  letter-spacing: 0;
  /* border-radius: 10px; */
}



.fc-today-bg-color {
  background-color: none !important;
}

/* .fc-day-today {
    background: linear-gradient(rgb(255, 255, 255,0.5), rgb(255, 255, 255, 0.5)) no-repeat center/4px 100%;
  
}  */

.fc-col-header-cell-cushion {
  color: var(--text);
  font-size: 13px;
  width: min-content;
}

.fc-scrollgrid-sync-table{
  height:88vh !important;
}


.fc-event {
  border-radius: 30px;
  height: 36px;
  padding-top: 7px;
  border:transparent;
  left: 0;
  margin: 10px;
  border: none;
}


.fc-event-main {
  display: flex;
  position: relative;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 0px;
}

/* time left */
.timeDifference {
  position: absolute;
  right: 0.4rem;
  top: -35%;
  font-size: 0.8rem;
  background: linear-gradient(transparent, #fa78b180, #fa78b180, transparent);
  border-radius: 20px;
  color: var(--black);
}

.timeDifference2 {
  position: absolute;
  left: 0.4rem;
  top: -35%;
  font-size: 0.8rem;
  background: linear-gradient(transparent, #5FFABD90, #5FFABD90, transparent);
  border-radius: 20px;
  color: var(--black);
}


.fc-scrollgrid {
  /* 흰색선 제거 */
  border: none !important;

}

.fc-scrollgrid::-webkit-scrollbar {

  /* Chrome, Safari, Opera*/
}

.fc-scroller {
  overscroll-behavior-x: none;
  overflow-y: hidden !important;
  /* 사파리에서 스크롤 띠용이 현상 제거 */
}

.fc-scroller::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
  overflow-y: hidden !important;
}

.fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: block !important;
}


.fc-daygrid-body {
  height: 100px;
}



.fc-event:has(i.aa) {
  background: var(--ewrwer, #ADF1EE);
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  /* background-image: url('./images/pattern2.png'); */
}

.fc-event:has(i.bb) {
  background: var(--ewrwer, #5FCFF9);
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  /* background-image: url('./images/pattern3.png'); */
}

/* 
그리드 색깔 */
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #ffffff50 !important;
}


/* 
오늘선 색깔 */
.today{
  color: var(--title) !important;
}

.todayline{
background: #fdd55b90;
margin-left:auto;
margin-right:auto;
max-width:2px;
z-index: 1000;
}



.fc-event:has(i.cc) {
  background: var(--sadfsaf, #EAFD66);
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  /* background-image: url('./images/pattern.png'); */

}


.fc-event:has(i.dd) {

  background: var(--rqqer, #abff8d);

}


.fc-event:has(i.ee) {

  background: #e8ffc2
}

.fc-event:has(i.ff) {

  background: #ffe3c2;
}

.fc-event:has(i.gg) {

  background: #FEBBF2;
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  /* background-image: url('./images/pattern3.png'); */

}

/* 
  hover collection */

.fc-event:hover {
  margin-top: 2px;
  margin-bottom: 1px;
}


.fc-event:has(i.z001) {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-image: url('./images/toothfairy.png'); */

}


.fc-license-message {
  display: none;
}

.legal {
  position:relative;
  font-size:0.8rem;
  color: var(--text);
  color: var(--text);
  font-weight:100;
  text-align: left;
  margin-top: auto;
  margin-bottom:2vh;
  line-height: 1rem;
}

.calendar-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.calendar-container.active {
  visibility: visible;
  opacity: 1;
}



/* mobile */




@media (max-width: 1150px) {
  .title {
    margin-left: 195px; /* 창이 882px 이하로 작아지면 left 값을 150px로 변경 */
  }
}



@media screen and (max-width: 599px) {
  :root {
    --fc-today-bg-color: inherit !important;
    /* background-image: url('./images/character_background.png'); */
    background: #181818;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --background: #181818;
    --white: #fdfdfd;
    --gray: #dddddd;
    --black: #242428;
    --shadow: #272727;
    --highlight: rgb(95, 250, 189);
    --title:rgb(253, 213, 91);
    --text: #fdfdfd;
    --textcon: #242424;
    --fc-today-bg-color: inherit !important;
    /* background-image: url('./images/character_background.png'); */
    background: #181818;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --background: #181818;
    --white: #fdfdfd;
    --gray: #dddddd;
    --black: #242428;
    --shadow: #272727;
    --highlight: rgb(95, 250, 189);
    --title:rgb(253, 213, 91);
    --text: #fdfdfd;
    --textcon: #242424;
  }

    .home {
        background-position-x: 2vw;
      background-position-y: 3vh;
      background-repeat: no-repeat;
      background-size: 6em;
      margin-bottom:6vh;
      /* height:100vh;
        width:100vw; */
    }
  
  
    .header_container {
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
      padding-top: 1vh;
      margin-right: 4vw;
      margin-left: 4vw;
      width: 92vw;
      height: 2vh;
      vertical-align: middle;
      justify-content: flex-end;
    }

    .title_container {
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
      padding-top: 1vh;
      height: 6vh;
      line-height:normal;
      vertical-align: middle;
      align-items: flex-start;
      margin-bottom:0vh;
    }
  
    .title {
      margin-left: 4vw;
      align-self:flex-end;
      font-size: 2rem;
      font-style: normal;
      font-weight: 900;
      margin-block-start: 0em;
      margin-block-end: 0em;
  
    }
  
    .subtitle {
      color: var(--title);
      margin-right:auto;
      align-self:end;
      font-weight:600;
      font-size:0.8rem;
      opacity: 0.9;
      margin-block-end: 0em;
      margin-block-start: 0em;
    }
  
  
    /* .sidebar {
        margin-left: auto;
        margin-right: 5%;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        background: #ADF1EE;
        display: block;
  
      } */
  
    .main_content_container {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      margin-bottom:6vmax;
      padding-bottom:2vmax;
      overflow: hidden;
    }
  

.menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top:2vmax;
  margin-bottom:0vmax;
  padding-bottom:0;
  margin-top:0;
  padding-top: 0%;
  margin-left: 4vw;
  margin-right: 4vw;
  height: fit-content;
  font-weight: 600;
  min-width: 92vw;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}


  
    .game_title_container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom:0vmax;
      padding-bottom:0;
      margin-top:0;
      padding-top: 0%;
      height: 10vh;
      font-weight: 600;
      min-width: 92vw;
      justify-content: flex-start;
      align-items: center;
      position: relative;
    }
  
  
    .game_title, .game_title:hover {
      width: 10vmin;
      height:10vmin;
      border-radius: 20px;
      overflow:hidden;
      margin-right:3vw;
    }

    .icon{
      border-radius: 20px;
      height: 100%;
        width: 100%;
        object-fit: contain;
}
  
  
    .tab-item {
      /* 모바일 화면에서는 전체 너비를 사용 */
      width: 100%;
      min-width: unset;
      margin-left: 0;
    }
  
    .tab-content {
      width: 100vw;
      margin:0;
      overflow: hidden;
      padding-bottom:1vh;
      height: 100vh;
    }
  
    .active-tab {
     border:1px solid var(--highlight);
    }

    .content-text {
      position: relative;
        color: #FDFDFD;
        text-align: left;
        height: min-content;
        font-size: 1rem;
        font-weight: 600;
        z-index: 99;
        color: var(--highlight);
        border-radius: 20px;
        background-color: transparent;
        margin-left: 4vw;
        padding-right: 0rem;
        padding-left: 0rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        margin-bottom: 0;
    }
  

    .calendar-container{
      height:max-content;
    }



    .fc {
      position: relative;
      background-color: #181818;
      top: 0px;
      left: 0px;
      height: 100vh;
      width: 100vw;
      z-index:999;
    }
  
    .cal-container {
      width:100%;
      height: 80%;
    }
  
  
  
    .todo-title {
      font-style: normal;
      line-height: normal;
      position: relative;
      min-height: 10vh;
      margin-top:0;
      height:180vw;
      max-height:240vw;
      width: 92vw;
      margin-right: 4vw;
      margin-bottom:6vh;
      margin-left: 4vw;
      background-color: var(--background);
      display: flex;
      flex-direction: column;
      align-items: center;
      vertical-align: middle;
      padding-top: 0;
    }
  
.pollcontainer{
  padding: 0.5vh 1vw;
  border-radius: 20px;
  width: 90vw;
  height: 20vh;
  margin-left: 4vw;
  margin-right: 4vw;
  max-height:80%;
  flex:3 3 30%;
  display:flex;
  overflow:hidden;
  margin-bottom:1vh;
}

.poll{
  display:flex;
  flex-direction: row;  
  border-radius: 20px;
  width: 92vw;
  flex-grow:1;
}

.poll1{
  border-radius: 20px;
  margin-bottom:0.5vh;
  flex: 1;
  display:flex;
  flex-direction: row;
  overflow:hidden;
}

.poll2{
  border-radius: 20px;
  margin-top:0.5vh;
  flex: 1;
  display:flex;
  overflow:hidden;
  flex-direction: row;
}



.polebutton{
  right:0;
  height:50%;
}


.poledone{
  right:0;
  height:50%;
}



  
    .tdc1 {
      margin-top:1vh;
      padding: 2vh 3vw;
      border-radius: 20px;
      flex:2 2 20%;
      width: 86vw;
      margin-left: 4vw;
      margin-right: 4vw;
      font-size: 1rem;
      font-style: normal;
      line-height: normal;
    }
  
    .tdc2 {
      margin-top: 2vh;
      padding: 1vh 3vw;
      margin-left: 4vw;
      margin-right: 4vw;
      border-radius: 20px;
      height: fit-content;
      width: 86vw;
      flex: 5 5 50%;
      height: 120vw;
      font-size: 1rem;
      font-style: normal;
      line-height: normal;
      margin-bottom: 1vh;
      overflow:scroll;
  
    }


.bili{
  border-radius: 20px;
  width:auto;
  height:40vh;
  }

  .legal{
    position:fixed;
    text-align: center;
    margin-left:2vw;
    margin-right:2vw;
    width:88vw;
    bottom:0;
    padding-top:1vmax;
    margin-top:1vh;
    height:auto;
    z-index:1;
  }

  
  
  
  
  }
  





  



/* white mode */


    :root.light {
      --fc-today-bg-color: inherit !important;
      /* background-image: url('./images/character_background.png'); */
      background: #fffffe;
      font-weight: 400;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color:#242428;
      --white: #fdfdfd;
      --gray: #dddddd;
      --black: #242428;
      --highlight: #38c3f6;
      --title:#38c3f6;
      --shadow: #dddddd;
      --background: #fdfdfd;
      --text:  #242424;
      --textcon:#fdfdfd;
    }
    

    .light .fc {
      background-color: #e6f3f86f;
    }
    
    .light .fc-event{
      border:1px solid var(--highlight);
    } 


    .light .fc-event:has(i.aa),
    .light .fc-event:has(i.bb),
    .light .fc-event:has(i.cc),
    .light .fc-event:has(i.dd),
    .light .fc-event:has(i.ee),
    .light .fc-event:has(i.ff),
    .light .fc-event:has(i.gg){
      background: var(--white);
    }
    
    /* 
    그리드 색깔 */
    .light .fc-theme-standard td,
    .light .fc-theme-standard th{
      border-color: #122c3650 !important;
    }
    
    .light .todayline{
      background:  #5FCFF990;
      }

@media screen and (max-width: 599px) {

    .light .fc {
      background-color:#eff9ff;
    }
  
  
}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    





